export interface Colors {
  primary: string;
  gray: string;
  grayLight: string;
  white: string;
  black: string;
}

const colors: Colors = {
  primary: "#1DA76C",
  gray: "#616161",
  grayLight:"#EEEFF0",
  white: "#FFFFFF",
  black: "#000000",
};

export interface Sizes {
  header: number;
}

const sizes: Sizes = {
  header: 70,
};

export {colors, sizes};
