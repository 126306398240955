// Interpretacion de empresario y microempresario
export const interpretModuleText = (text: string): string => {
  switch (text) {
    case "1":
      return "empresario";
    case "2":
      return "microempresario";
    default:
      return "Texto no reconocido";
  }
};
