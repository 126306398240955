import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button, { ButtonProps } from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { colors, sizes } from "../styles/theme.tsx";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
const pages = [
  "Inicio",
  "Nuestros Servicios",
  "Sobre Nosotros",
  "Centro de ayuda",
];
const ourServicesSub = [
  "Contrato de domicilio",
  "Domicilios locales",
  "Entrega y transporte",
  "Estrategias de marketing",
  "Mensajeria Digital",
];
const aboutUsSub = [
  "Cultura organizacional",
  "Quienes somos",
  "Trabaja con nosotros",
];

const loginSub = [
  "Inicia como empresario",
  "Inicia como microempresario",
];

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: colors.primary,
  backgroundColor: colors.white,
  "&:hover": {
    backgroundColor: "transparent",
    border: `1px solid ${colors.white}`,
    color: colors.white,
  },
  borderRadius: 29,
  fontWeight: 600,
}));

function Header({ setModuleText }) {
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [openSubMenuMobile, setOpenSubMenuMobile] = React.useState(false);
  const [openAboutUsSubMenuMobile, setOpenAboutUsSubMenuMobile] =
    React.useState(false);
  const [anchorElServicios, setAnchorElServicios] =
    React.useState<null | HTMLElement>(null);
  const [anchorElAboutUs, setAnchorElAboutUs] =
    React.useState<null | HTMLElement>(null);
  const [anchorElLogin, setAnchorElLogin] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setOpenSubMenuMobile(false); // Cerrar el submenú cuando se cierra el menú principal
    setOpenAboutUsSubMenuMobile(false); // Cerrar el submenú de "Sobre Nosotros"
  };

  // Alternar el submenú de "Nuestros Servicios" en Desktop
  const handleToggleSubMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElServicios(anchorElServicios ? null : event.currentTarget);
  };

  // Alternar el submenú de "Sobre Nosotros" en Desktop
  const handleToggleAboutUsSubMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAboutUs(anchorElAboutUs ? null : event.currentTarget);
  };

  const handleCloseSubMenu = () => {
    setAnchorElServicios(null);
  };

  const isActive = (path: string) => location.pathname === path;

  const handleToggleSubMenuMobile = () => {
    setOpenSubMenuMobile(!openSubMenuMobile); // Alternar el submenú mobile
    setOpenAboutUsSubMenuMobile(false); // Cerrar el otro submenú si está abierto
  };

  const handleToggleAboutUsSubMenuMobile = () => {
    setOpenAboutUsSubMenuMobile(!openAboutUsSubMenuMobile); // Alternar el submenú mobile de "Sobre Nosotros"
    setOpenSubMenuMobile(false); // Cerrar el otro submenú si está abierto
  };

  // Abrir menú de Ingresar
  const handleToggleLoginMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLogin(anchorElLogin ? null : event.currentTarget);
  };

  // Cerrar menú de Ingresar
  const handleCloseLoginMenu = () => {
    setAnchorElLogin(null);
  };

  const handleCloseAboutUsMenu = () => {
    setAnchorElAboutUs(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 1000,
        background: colors.primary,
        padding: { xs: "5px", md: "5px 105px 5px 48px" },
        height: sizes.header,
      }}
    >
      <Container
        sx={{
          maxWidth: "100% !important",
          padding: "0 !important",
          height: "100%",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            justifyContent: { xs: "space-between", md: "inherit" },
            height: "100%",
            minHeight: "100% !important",
          }}
        >
          {/* Mobile */}
          <Box
            sx={{ display: { xs: "flex", md: "none" }, position: "absolute" }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {pages.map((page) => (
                <div key={page}>
                  {page === "Nuestros Servicios" ? (
                    <>
                      <MenuItem onClick={handleToggleSubMenuMobile}>
                        <Typography
                          sx={{
                            textAlign: "center",
                            color: isActive("/nuestros-servicios")
                              ? colors.primary
                              : colors.black,
                          }}
                        >
                          {page}
                        </Typography>
                      </MenuItem>
                      {/* Mostrar submenú solo si openSubMenuMobile está en true */}
                      {openSubMenuMobile &&
                        ourServicesSub.map((subPage) => (
                          <MenuItem key={subPage} onClick={handleCloseNavMenu}>
                            <Link
                              to={`/${subPage
                                .toLowerCase()
                                .replace(/ /g, "-")}`}
                              style={{
                                textDecoration: "none",
                                color: isActive(
                                  `/${subPage.toLowerCase().replace(/ /g, "-")}`
                                )
                                  ? colors.primary
                                  : colors.black,
                                paddingLeft: "20px",
                              }}
                            >
                              <Typography>{subPage}</Typography>
                            </Link>
                          </MenuItem>
                        ))}
                    </>
                  ) : page === "Sobre Nosotros" ? (
                    <>
                      <MenuItem onClick={handleToggleAboutUsSubMenuMobile}>
                        <Typography
                          sx={{
                            textAlign: "center",
                            color: isActive("/sobre-nosotros")
                              ? colors.primary
                              : colors.black,
                          }}
                        >
                          {page}
                        </Typography>
                      </MenuItem>
                      {openAboutUsSubMenuMobile &&
                        aboutUsSub.map((subPage) => (
                          <MenuItem key={subPage} onClick={handleCloseNavMenu}>
                            <Link
                              to={`/${subPage
                                .toLowerCase()
                                .replace(/ /g, "-")}`}
                              style={{
                                textDecoration: "none",
                                color: isActive(
                                  `/${subPage.toLowerCase().replace(/ /g, "-")}`
                                )
                                  ? colors.primary
                                  : colors.black,
                                paddingLeft: "20px",
                              }}
                            >
                              <Typography>{subPage}</Typography>
                            </Link>
                          </MenuItem>
                        ))}
                    </>
                  ) : (
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Link
                        to={
                          page === "Inicio"
                            ? "/"
                            : `/${page.toLowerCase().replace(/ /g, "-")}`
                        }
                        style={{
                          textDecoration: "none",
                          color: isActive(
                            page === "Inicio"
                              ? "/"
                              : `/${page.toLowerCase().replace(/ /g, "-")}`
                          )
                            ? colors.primary
                            : colors.black,
                        }}
                      >
                        <Typography sx={{ textAlign: "center" }}>
                          {page}
                        </Typography>
                      </Link>
                    </MenuItem>
                  )}
                </div>
              ))}
            </Menu>
          </Box>

          {/* logo */}
          <Box
            sx={{
              width: { xs: "100%", md: "auto" },
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: 40 }}>
              <Link to="/" style={{ textDecoration: "none" }}>
                <img
                  src={require("../assets/image/LogoVerticalBlanco.webp")}
                  alt={""}
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Link>
            </Box>
          </Box>

          {/* Desktop */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "space-around",
              paddingX: { md: "50px !important", xl: "200px !important" },
              alignItems: "center",
            }}
          >
            {pages.map((page) =>
              page === "Nuestros Servicios" ? (
                <Box key={page} sx={{ position: "relative" }}>
                  <Button
                    onClick={handleToggleSubMenu}
                    sx={{
                      my: 2,
                      fontWeight: "bold",
                      color: "white",
                      textDecoration: "none",
                      lineHeight: 1,
                      textAlign: "center",
                    }}
                    endIcon={
                      anchorElServicios ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )
                    }
                  >
                    {page}
                  </Button>
                  <Menu
                    anchorEl={anchorElServicios}
                    open={Boolean(anchorElServicios)}
                    onClose={handleCloseSubMenu}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    {ourServicesSub.map((subPage) => (
                      <MenuItem key={subPage} onClick={handleCloseSubMenu}>
                        <Link
                          to={`/${subPage.toLowerCase().replace(/ /g, "-")}`}
                          style={{
                            textDecoration: "none",
                            color: isActive(
                              `/${subPage.toLowerCase().replace(/ /g, "-")}`
                            )
                              ? colors.primary
                              : colors.black,
                          }}
                        >
                          <Typography>{subPage}</Typography>
                        </Link>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              ) : page === "Sobre Nosotros" ? (
                <Box key={page} sx={{ position: "relative" }}>
                  <Button
                    onClick={handleToggleAboutUsSubMenu}
                    sx={{
                      my: 2,
                      fontWeight: "bold",
                      color: "white",
                      lineHeight: 1,
                      textAlign: "center",
                    }}
                    endIcon={
                      anchorElAboutUs ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )
                    }
                  >
                    {page}
                  </Button>
                  <Menu
                    anchorEl={anchorElAboutUs}
                    open={Boolean(anchorElAboutUs)}
                    onClose={handleCloseAboutUsMenu}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    {aboutUsSub.map((subPage) => (
                      <MenuItem key={subPage} onClick={handleCloseAboutUsMenu}>
                        <Link
                          to={`/${subPage.toLowerCase().replace(/ /g, "-")}`}
                          style={{
                            textDecoration: "none",
                            color: isActive(
                              `/${subPage.toLowerCase().replace(/ /g, "-")}`
                            )
                              ? colors.primary
                              : colors.black,
                          }}
                        >
                          {subPage}
                        </Link>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              ) : (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to={
                    page === "Inicio"
                      ? "/"
                      : `/${page.toLowerCase().replace(/ /g, "-")}`
                  }
                  sx={{
                    my: 2,
                    display: "block",
                    fontWeight: "bold",
                    color: "white",
                    lineHeight: 1,
                    textAlign: "center",
                    textDecoration: isActive(
                      page === "Inicio"
                        ? "/"
                        : `/${page.toLowerCase().replace(/ /g, "-")}`
                    )
                      ? "underline"
                      : "none",
                  }}
                >
                  {page}
                </Button>
              )
            )}
          </Box>

          {/* boton */}
          <Button
            onClick={handleToggleLoginMenu}
            sx={{
              flexGrow: 0,
              position: { xs: "absolute", md: "relative" },
              right: 0,
              textDecoration: "none",
              padding:0,
            }}
          >
            <Box
              sx={{
                width: { md: 130 },
              }}
            >
              <ColorButton
                variant="contained"
                style={{ width: "100%" }}
                endIcon={
                  anchorElLogin ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
              >
                INGRESAR
              </ColorButton>
            </Box>
          </Button>
          <Menu
            anchorEl={anchorElLogin}
            open={Boolean(anchorElLogin)}
            onClose={handleCloseLoginMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{
              marginTop: 5,
            }}
          >
            {loginSub.map((subPage) => (
              <MenuItem key={subPage} onClick={handleCloseLoginMenu} >
                <Link
                  to="/iniciar-sesion"
                  style={{
                    textDecoration: "none",
                    color: colors.black
                  }}
                  onClick={() => {
                    subPage === "Inicia como empresario"
                      ? setModuleText("1")
                      : setModuleText("2");
                  }}
                >
                  {subPage}
                </Link>
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
