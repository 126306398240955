// App.tsx
import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./ScrollToTop.tsx";
import Header from "./components/Header.tsx";
import AppRoutes from "./AppRoutes.tsx"; // Asegúrate de que la ruta sea correcta

function App() {
  const [moduleText, setModuleText] = useState("1");

  return (
    <Router>
      <ScrollToTop />
      <Header setModuleText={setModuleText} />
      <AppRoutes moduleText={moduleText} setModuleText={setModuleText} />
    </Router>
  );
}

export default App;
