import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { colors, sizes } from "../../styles/theme.tsx";

export default function WhoAreWe() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          flex: "1",
          flexGrow: 1,
          marginTop: `${sizes.header}px`,
        }}
      >
        <Box
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
          sx={{ flexGrow: 1, width: "85%", margin: "0 auto" }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 32, md: 43 },
              color: colors.primary,
              textTransform: "uppercase",
            }}
            lineHeight={1}
            my={{ xs: 10, lg: "107px" }}
          >
            ¿QUIÉNES SOMOS?
          </Typography>

          <Grid
            sx={{
              background: colors.white,
              height: "auto",
              borderRadius: "18px",
              boxShadow: "0px 7px 41.5px -11px #00000040",
              padding: { xs: "40px", sm: "70px" },
              position: "relative",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "100px" ,
            }}
          >
            <Box
              sx={{
                width: 10,
                height: 136,
                background: colors.primary,
                position: "absolute",
                top: { xs: "12%", sm: "17%", md: "22%", xl: "33%" },
                left: 0,
                borderRadius: 2,
                transform: "translateY(-50%)",
              }}
            />

            <Box>
              <Typography
                sx={{ fontSize: { xs: 20, md: 23 }, color: colors.gray }}
                lineHeight={1.3}
              >
                <b>Dinamia</b> es una empresa dedicada a ofrecer soluciones
                integrales de mensajería, domicilios, transporte y marketing
                digital.
                <br />
                <br />
                Nuestro compromiso es conectar a personas y empresas a través de
                un servicio rápido, seguro y eficiente, adaptado a las
                necesidades del mercado actual. Contamos con una red de
                domiciliarios activos, una plataforma virtual y una app móvil
                que nos permite operar de manera ágil y eficaz.
                <br />
                <br />
                Además, brindamos productos de marketing para ayudar a los
                negocios a destacarse y crecer en un entorno competitivo.
                Creemos en la innovación, la tecnología y el servicio al cliente
                como pilares fundamentales de nuestro éxito.
              </Typography>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
