import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { colors, sizes } from "../../styles/theme.tsx";

export default function OrganizationalCulture() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          flex: "1",
          flexGrow: 1,
          marginTop: `${sizes.header}px`,
        }}
      >
        <Box
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
          sx={{ flexGrow: 1, width: "85%", margin: "0 auto" }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 32, md: 43 },
              color: colors.primary,
              textTransform: "uppercase",
            }}
            lineHeight={1}
            my={{ xs: 10, lg: "107px" }}
          >
            CULTURA ORGANIZACIONAL
          </Typography>

          <Grid
            sx={{
              background: colors.white,
              height: "auto",
              borderRadius: "18px",
              boxShadow: "0px 7px 41.5px -11px #00000040",
              padding: {xs:"40px",sm:"70px"},
              position: "relative",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: { lg: "100px" },
            }}
          >
            <Box
              sx={{
                width: 10,
                height: 136,
                background: colors.primary,
                position: "absolute",
                top: { xs: "15%", sm: "17%", md: "22%", xl: "26%" },
                left: 0,
                borderRadius: 2,
                transform: "translateY(-50%)",
              }}
            />

            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 32, md: 43 },
                  color: colors.primary,
                  textTransform: "uppercase",
                }}
                lineHeight={1}
              >
                MISIÓN
              </Typography>

              <Typography
                sx={{
                  fontSize: { xs: 20, md: 23 },
                  color: colors.gray,
                  marginTop: "20px",
                }}
                lineHeight={1.3}
              >
                Nuestra misión es conectar empresas y personas a través de
                servicios de mensajería y domicilios confiables, rápidos y
                accesibles a nivel nacional.
                <br />
                <br />
                Facilitamos el transporte de paquetes y mensajería de manera
                eficiente, tanto física como digitalmente. Además, potenciamos a
                las empresas con soluciones de marketing personalizadas,
                ayudándolas a crecer y destacar en el mercado.
                <br />
                <br />
                Apostamos por la innovación, la tecnología y el compromiso con
                la excelencia para hacer más fácil la vida de nuestros clientes
                y sus negocios.
              </Typography>
            </Box>
          </Grid>
          <Grid
            sx={{
              height: "auto",
              padding: {xs:"40px",sm:"70px"},
              position: "relative",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: { lg: "100px" },
            }}
          >
            <Box
              sx={{
                width: 10,
                height: 136,
                background: colors.primary,
                position: "absolute",
                top: { xs: "12%", sm: "17%", md: "22%", xl: "26%" },
                right: 0,
                borderRadius: 2,
                transform: "translateY(-50%)",
              }}
            />

            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 32, md: 43 },
                  color: colors.primary,
                  textTransform: "uppercase",
                  textAlign: "right",
                }}
                lineHeight={1}
              >
                VISIÓN
              </Typography>

              <Typography
                sx={{
                  fontSize: { xs: 22, md: 23 },
                  color: colors.gray,
                  marginTop: "20px",
                }}
                lineHeight={1.3}
              >
                Ser la plataforma líder a nivel nacional en soluciones
                integrales de mensajería, entrega y marketing digital,
                reconocida por nuestra innovación, eficiencia y capacidad para
                impulsar el crecimiento de pequeñas y grandes empresas.
                <br />
                <br />
                Aspiramos a ser un puente confiable que conecte a personas y
                negocios, ofreciendo un servicio de alta calidad que transforme
                la manera en que se comunican y operan en el entorno digital y
                físico.
                <br />
                <br />
                Queremos ser una herramienta clave para el éxito de nuestros
                clientes, contribuyendo al desarrollo económico y social del
                país.
              </Typography>
            </Box>
          </Grid>
          <Grid
            sx={{
              background: colors.white,
              height: "auto",
              borderRadius: "18px",
              boxShadow: "0px 7px 41.5px -11px #00000040",
              padding: {xs:"40px",sm:"70px"},
              position: "relative",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "100px",
            }}
          >
              <Box
              sx={{
                width: 10,
                height: 136,
                background: colors.primary,
                position: "absolute",
                top: "50%",
                left: 0,
                borderRadius: 2,
                transform: "translateY(-50%)",
              }}
            />

            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 32, md: 43 },
                  color: colors.primary,
                  textTransform: "uppercase",
                }}
                lineHeight={1}
              >
                VALORES
              </Typography>

              <Typography
                sx={{
                  fontSize: { xs: 20, md: 23 },
                  color: colors.gray,
                  marginTop: "20px",
                }}
                lineHeight={1.3}
              >
                Compromiso, innovación, confiabilidad, responsabilidad social,
                trabajo en equipo, sostenibilidad
              </Typography>
            </Box>
          </Grid>
        </Box>
      </Box>
      {/* <Box sx={{ width: "100%" }}>
        <Footer />
      </Box> */}
    </Box>
  );
}
