// AppRoutes.tsx
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home.tsx";
import LocalAddresses from "./pages/OurServices/LocalAddresses.tsx";
import DeliveryAndTransportation from "./pages/OurServices/DeliveryAndTransportation.tsx";
import DigitalMessaging from "./pages/OurServices/DigitalMessaging.tsx";
import DomicileContract from "./pages/OurServices/DomicileContract.tsx";
import MarketingStrategies from "./pages/OurServices/MarketingStrategies.tsx";
import OrganizationalCulture from "./pages/AboutUs/OrganizationalCulture.tsx";
import WhoAreWe from "./pages/AboutUs/WhoAreWe.tsx";
import ContactUs from "./pages/AboutUs/ContactUs.tsx";
import Login from "./pages/User/Login.tsx";
import Register from "./pages/User/Register.tsx";
import RegisterForm from "./pages/User/RegisterForm.tsx";
import WorkWithUs from "./pages/AboutUs/WorkWithUs.tsx";
import Footer from "./components/Footer.tsx";

const AppRoutes = ({ moduleText, setModuleText }) => {
  const location = useLocation();
  const noFooterRoutes = [
    "/iniciar-sesion",
    "/registrarse",
    "/formulario-registro",
  ];

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nuestros-servicios" element={<LocalAddresses />} />
        <Route path="/domicilios-locales" element={<LocalAddresses />} />
        <Route
          path="/entrega-y-transporte"
          element={<DeliveryAndTransportation />}
        />
        <Route path="/mensajeria-digital" element={<DigitalMessaging />} />
        <Route path="/contrato-de-domicilio" element={<DomicileContract />} />
        <Route
          path="/estrategias-de-marketing"
          element={<MarketingStrategies />}
        />
        <Route path="/sobre-nosotros" element={<WhoAreWe />} />
        <Route path="/quienes-somos" element={<WhoAreWe />} />
        <Route
          path="/cultura-organizacional"
          element={<OrganizationalCulture />}
        />
        <Route path="/trabaja-con-nosotros" element={<WorkWithUs />} />
        <Route path="/centro-de-ayuda" element={<ContactUs />} />
        <Route
          path="/iniciar-sesion"
          element={<Login moduleText={moduleText} />}
        />
        <Route path="/registrarse" element={<Register setModuleText={setModuleText}/>} />
        <Route path="/formulario-registro" element={<RegisterForm moduleText={moduleText}/>} />
        <Route path="*" element={<Navigate to="/" replace />} />{" "}
      </Routes>
      {/* Renderiza el Footer solo si la ruta no está en el arreglo noFooterRoutes */}
      {!noFooterRoutes.includes(location.pathname) && (
        <Footer setModuleText={setModuleText} />
      )}
    </>
  );
};

export default AppRoutes;
