import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  ButtonProps,
  Checkbox,
  TextField,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";
import { colors, sizes } from "../../styles/theme.tsx";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import EmailIcon from "@mui/icons-material/Email";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import PhoneIcon from "@mui/icons-material/Phone";
import DescriptionIcon from "@mui/icons-material/Description";
import HouseIcon from "@mui/icons-material/House";
import { useNavigate } from "react-router-dom";

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: colors.white,
  backgroundColor: colors.primary,
  "&:hover": {
    backgroundColor: colors.white,
    border: `1px solid ${colors.primary}`,
    color: colors.primary,
  },
  borderRadius: 19,
  width: 245,
  fontWeight: 700,
  margin: "0 auto",
  marginTop: 20,
}));

const CssTextField = styled(TextField)({
  "& label": {
    paddingLeft: "15px",
    transition: "padding-left 0.4s ease-in-out",
  },
  "& label.Mui-focused": {
    color: colors.primary,
    paddingLeft: "0px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
      borderRadius: 10,
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
      borderRadius: 10,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.primary,
      borderRadius: 10,
    },
    "& .MuiInputBase-inputMultiline": {
      resize: "vertical",
    },
  },
  background: colors.grayLight,
  width: "70%",
  marginBottom: "12px",
  borderRadius: 10,
});

export default function RegisterForm({ moduleText }) {
  const [send, setSend] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({
    nombre: "",
    correo: "",
    cedula: "",
    numero_contacto: "",
    nit: "",
    direccion: "",
    contraseña: "",
    descripcion: "",
    policy: false,
  });
  const [dataFormValidated, setDataFormValidated] = useState({
    nombre: false,
    correo: false,
    cedula: false,
    numero_contacto: false,
    nit: false,
    direccion: false,
    contraseña: false,
    descripcion: false,
    policy: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    const isNombreValid = data.nombre.trim() !== "";
    const isCorreoValid = /^\S+@\S+\.\S+$/.test(data.correo);
    const isCedulaValid =
      data.cedula.trim() !== "" &&
      !isNaN(parseInt(data.cedula)) &&
      data.cedula.length === 10;
    const isTelefonoValid =
      data.numero_contacto.trim() !== "" &&
      !isNaN(parseInt(data.numero_contacto));
    const isNitValid = data.nit.trim() !== "";
    const isDirValid = data.direccion.trim() !== "";
    const isContraseñaValid = data.contraseña.trim() !== "";
    const isMensajeValid = data.descripcion.trim() !== "";

    setDataFormValidated({
      nombre: !isNombreValid,
      correo: !isCorreoValid,
      cedula: !isCedulaValid,
      numero_contacto: !isTelefonoValid,
      nit: moduleText === "1" ? !isNitValid : false,
      direccion: !isDirValid,
      contraseña: !isContraseñaValid,
      descripcion: !isMensajeValid,
      policy: data.policy,
    });

    // Retorna true solo si todos los campos son válidos
    const isFormValid =
      isNombreValid &&
      isCorreoValid &&
      isCedulaValid &&
      isTelefonoValid &&
      isDirValid &&
      isContraseñaValid &&
      isMensajeValid &&
      data.policy;

    // Si moduleText es "1", añade la validación de NIT
    return moduleText === "1" ? isNitValid && isFormValid : isFormValid;
  };

  // Función para verificar si los campos están vacíos y policy es false
  const isFormIncomplete = () => {
    // Define las claves a validar en función del valor de moduleText
    const requiredFields = [
      "nombre",
      "correo",
      "cedula",
      "numero_contacto",
      "direccion",
      "contraseña",
      "descripcion",
      "policy",
    ];

    // Si moduleText es "1", agrega 'nit' a los campos requeridos
    if (moduleText === "1") {
      requiredFields.unshift("nit");
    }

    // Verifica si alguno de los campos requeridos está vacío
    return requiredFields.some((field) => !data[field]);
  };

  const handleSend = () => {
    if (validateForm()) {
      setIsLoading(true);
      const randomTime = Math.floor(Math.random() * (5000 - 2000 + 1)) + 2000;
      setTimeout(() => {
        setIsLoading(false);
        setSend(true);
      }, randomTime);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          flex: "1",
          flexGrow: 1,
          marginTop: `${sizes.header}px`,
          paddingTop: "60px",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            flexGrow: 1,
            width: { xs: "95%", md: "70%", lg: "60%", xl: "40%" },
            margin: "0 auto",
          }}
        >
          {!send && (
            <Box sx={{ position: "relative", width: "100%" }}>
              <div
                style={{
                  width: 10,
                  height: 89,
                  background: colors.primary,
                  position: "absolute",
                  top: "45%",
                  left: 0,
                  borderRadius: 2,
                  transform: "translateY(-50%)",
                }}
              />
              <Box pl="50px">
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: 32, md: 43 },
                    color: colors.primary,
                    textTransform: "uppercase",
                  }}
                  lineHeight={1}
                >
                  REGÍSTRATE
                </Typography>

                <Typography
                  sx={{
                    fontSize: { xs: 21.5, md: 24.5 },
                    color: colors.gray,
                    marginTop: "10px",
                    lineHeight: 1.3,
                  }}
                >
                  ¡Las mejores soluciones para ti!
                </Typography>
              </Box>
            </Box>
          )}

          <Box>
            <Grid
              sx={{
                background: colors.white,
                height: "auto",
                borderRadius: "18px",
                boxShadow: "0px 7px 41.5px -11px #00000040",
                padding: {
                  xs: "40px 20px 100px 20px",
                  sm: " 70px",
                },
                position: "relative",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                mb: 10,
              }}
              mt={send ? 0 : 10}
            >
              {send ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                    height: "100%",
                  }}
                  className="zoom-in"
                >
                  <Box>
                    <Box
                      sx={{
                        width: { xs: 180, lg: 235 },
                        height: { xs: 180, lg: 235 },
                        margin: "0 auto",
                      }}
                    >
                      <img
                        src={require("../../assets/image/check.webp")}
                        alt={""}
                        loading="lazy"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                    <Typography style={{ textAlign: "center" }}>
                      <Typography
                        sx={{
                          color: "#1DA76C",
                          lineHeight: 0.9,
                          fontWeight: "bold",
                          letterSpacing: -1,
                          fontSize: {
                            xs: "1.5rem",
                            lg: "1.6rem",
                            xl: "2rem",
                          },
                        }}
                      >
                        REGISTRO COMPLETO
                      </Typography>
                      <Typography
                        style={{
                          textAlign: "center",
                          color: "#616161",
                          fontSize: 19,
                        }}
                        mt={0.5}
                      >
                        ¡Tus datos han sido enviados!
                      </Typography>
                    </Typography>
                  </Box>

                  <Typography style={{ textAlign: "center" }} mt={10} mb={12}>
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#616161",
                        fontSize: 19,
                        lineHeight: 1.3,
                      }}
                      mx={8}
                      mt={0.5}
                    >
                      Esta información será verificada, debes estar pendiente a
                      tu
                      <b> celular o correo electrónico</b>
                    </Typography>
                  </Typography>
                  <ColorButton
                    variant="contained"
                    sx={{ fontSize: 20 }}
                    onClick={() => {
                      navigate("/");
                      setSend(false);
                    }}
                  >
                    Finalizar
                  </ColorButton>
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      width: { xs: 180, lg: 235 },
                      height: { xs: 180, lg: 235 },
                      margin: "0 auto",
                      marginTop: { xs: "-80px", sm: "-120px" },
                    }}
                  >
                    <img
                      src={require("../../assets/image/user.webp")}
                      alt={""}
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    mt={6}
                  >
                    <CssTextField
                      placeholder="Nombre Completo"
                      id="custom-css-outlined-input"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      sx={{ width: { xs: "100%", sm: "70%" } }}
                      value={data.nombre}
                      onChange={(e) =>
                        setData({ ...data, nombre: e.target.value })
                      }
                      error={dataFormValidated.nombre}
                      helperText={
                        dataFormValidated.nombre
                          ? "El campo nombre es obligatorio"
                          : ""
                      }
                      disabled={isLoading}
                    />
                    <CssTextField
                      placeholder="Número de cédula"
                      id="custom-css-outlined-input"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <BrandingWatermarkIcon />
                          </InputAdornment>
                        ),
                        
                      }}
                      variant="outlined"
                      sx={{ width: { xs: "100%", sm: "70%" } }}
                      value={data.cedula}
                      onChange={(e) =>
                        setData({ ...data, cedula: e.target.value })
                      }
                      onKeyPress={(e) => {
                        // Evitar que se ingresen caracteres que no son números
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault(); 
                        }
                      }}
                      error={dataFormValidated.cedula}
                      helperText={
                        data.cedula.length > 0 && data.cedula.length !== 10
                          ? "El campo cedula debe tener 10 caracteres"
                          : ""
                      }
                      disabled={isLoading}
                    />
                    <CssTextField
                      placeholder="Número de contacto"
                      id="custom-css-outlined-input"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      sx={{ width: { xs: "100%", sm: "70%" } }}
                      value={data.numero_contacto}
                      onChange={(e) =>
                        setData({ ...data, numero_contacto: e.target.value })
                      }
                      onKeyPress={(e) => {
                        // Evitar que se ingresen caracteres que no son números
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault(); 
                        }
                      }}
                      error={dataFormValidated.numero_contacto}
                      helperText={
                        dataFormValidated.numero_contacto
                          ? "El campo número de contacto es obligatorio"
                          : ""
                      }
                      disabled={isLoading}
                    />
                    {moduleText === "1" && (
                      <CssTextField
                        placeholder="Nit de la empresa"
                        id="custom-css-outlined-input"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DescriptionIcon />
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        sx={{ width: { xs: "100%", sm: "70%" } }}
                        value={data.nit}
                        onChange={(e) =>
                          setData({ ...data, nit: e.target.value + "" })
                        }
                        error={dataFormValidated.nit}
                        helperText={
                          dataFormValidated.nit
                            ? "El campo nit es obligatorio"
                            : ""
                        }
                        disabled={isLoading}
                      />
                    )}

                    <CssTextField
                      placeholder="Dirección del negocio"
                      id="custom-css-outlined-input"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HouseIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      sx={{ width: { xs: "100%", sm: "70%" } }}
                      value={data.direccion}
                      onChange={(e) =>
                        setData({ ...data, direccion: e.target.value })
                      }
                      error={dataFormValidated.direccion}
                      helperText={
                        dataFormValidated.direccion
                          ? "El campo dirección es obligatorio"
                          : ""
                      }
                      disabled={isLoading}
                    />
                    <CssTextField
                      placeholder="Correo electrónico"
                      id="custom-css-outlined-input"
                      type="email"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      sx={{ width: { xs: "100%", sm: "70%" } }}
                      value={data.correo}
                      onChange={(e) => {
                        setData({ ...data, correo: e.target.value });
                        setDataFormValidated({
                          ...dataFormValidated,
                          correo: false,
                        });
                      }}
                      error={dataFormValidated.correo}
                      helperText={
                        dataFormValidated.correo
                          ? "Ingresa un correo válido"
                          : ""
                      }
                      disabled={isLoading}
                    />
                    <CssTextField
                      placeholder="Contraseña"
                      id="custom-css-outlined-input"
                      type="password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      sx={{ width: { xs: "100%", sm: "70%" } }}
                      value={data.contraseña}
                      onChange={(e) =>
                        setData({ ...data, contraseña: e.target.value })
                      }
                      error={dataFormValidated.contraseña}
                      helperText={
                        dataFormValidated.contraseña
                          ? "El campo contraseña es obligatorio"
                          : ""
                      }
                      disabled={isLoading}
                    />
                    <CssTextField
                      placeholder="Cuéntanos un poco sobre tu empresa"
                      id="custom-css-outlined-input"
                      multiline
                      maxRows={4}
                      minRows={4}
                      sx={{ width: { xs: "100%", sm: "70%" } }}
                      value={data.descripcion}
                      onChange={(e) =>
                        setData({ ...data, descripcion: e.target.value })
                      }
                      error={dataFormValidated.descripcion}
                      helperText={
                        dataFormValidated.descripcion
                          ? "El campo descripcion es obligatorio"
                          : ""
                      }
                      disabled={isLoading}
                    />
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Checkbox
                        onChange={(e) =>
                          setData({ ...data, policy: e.target.checked })
                        }
                        disabled={isLoading}
                      />
                      <Typography
                        sx={{
                          fontSize: 13,
                          color: colors.black,
                        }}
                      >
                        He leído y acepto la{" "}
                        <span style={{ color: colors.primary }}>
                          Política de Tratamiento de Datos
                        </span>
                      </Typography>
                    </Box>

                    <ColorButton
                      variant="contained"
                      sx={{ fontSize: 20 }}
                      onClick={handleSend}
                      disabled={isLoading || isFormIncomplete()}
                    >
                      {isLoading ? (
                        <CircularProgress
                          style={{ width: 25, height: 25, padding: 5 }}
                          color="inherit"
                        />
                      ) : (
                        "Continuar"
                      )}
                    </ColorButton>
                  </Box>
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
