import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";
import { colors, sizes } from "../../styles/theme.tsx";
import { useNavigate } from "react-router-dom";
const CssTextField = styled(TextField)({
  "& label": {
    paddingLeft: "15px", // Aplica el padding-left cuando el label no está enfocado
    transition: "padding-left 0.4s ease-in-out", // Añade una transición suave
  },
  "& label.Mui-focused": {
    color: colors.primary,
    paddingLeft: "0px", // Elimina el padding cuando el label está enfocado
  },
  "& label.MuiInputLabel-shrink": {
    paddingLeft: "0px", // Elimina el padding cuando el label está shrinked (arriba)
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
      borderRadius: 10,
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
      borderRadius: 10,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.primary,
      borderRadius: 10,
    },
  },
  background: colors.grayLight,
  marginBottom: "21px",
  borderRadius: 10,
});

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: colors.white,
  backgroundColor: colors.primary,
  "&:hover": {
    backgroundColor: colors.white,
    border: `1px solid ${colors.primary}`,
    color: colors.primary,
  },
  borderRadius: 19,
  width: 245,
  fontWeight: 700,
  margin: "0 auto",
}));
export default function WorkWithUs() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [send, setSend] = useState(false);
  const [data, setData] = useState({
    nombre: "",
    ciudad: "",
    numero_contacto: "",
    correo: "",
    policy: false,
  });

  const [dataFormValidated, setDataFormValidated] = useState({
    nombre: false,
    ciudad: false,
    numero_contacto: false,
    correo: false,
    policy: false,
  });

  const validateForm = () => {
    const isNombreValid = data.nombre.trim() !== "";
    const isCiudadValid = data.ciudad.trim() !== "";
    const isTelefonoValid =
      data.numero_contacto.trim() !== "" &&
      !isNaN(parseInt(data.numero_contacto));
    const isCorreoValid = /^\S+@\S+\.\S+$/.test(data.correo);

    setDataFormValidated({
      nombre: !isNombreValid,
      correo: !isCorreoValid,
      ciudad: !isCiudadValid,
      numero_contacto: !isTelefonoValid,
      policy: data.policy,
    });

    return (
      isNombreValid &&
      isCorreoValid &&
      isCiudadValid &&
      isTelefonoValid &&
      data.policy
    );
  };

  // Función para verificar si los campos están vacíos y policy es false
  const isFormIncomplete = () => {
    return (
      !data.nombre ||
      !data.correo ||
      !data.ciudad ||
      !data.numero_contacto ||
      !data.policy ||
      isLoading
    );
  };

  const handleSend = () => {
    if (validateForm()) {
      setIsLoading(true);
      const randomTime = Math.floor(Math.random() * (5000 - 2000 + 1)) + 2000;
      setTimeout(() => {
        setIsLoading(false);
        setSend(true);
      }, randomTime);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          flex: "1",
          flexGrow: 1,
          marginTop: `${sizes.header}px`,
          paddingTop: "60px",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            flexGrow: 1,
            width: { xs: "95%", lg: "80%", xl: "70%" },
            margin: "0 auto",
          }}
        >
          {/* Titulo */}
          <Box
            sx={{
              padding: "50px",
              position: "relative",
              display: "flex",
              width: { md: "75%", lg: "60%" },
              margin: 0,
            }}
          >
            <div
              style={{
                width: 10,
                height: 120,
                background: colors.primary,
                position: "absolute",
                top: "50%",
                left: 0,
                borderRadius: 2,
                transform: "translateY(-50%)",
              }}
            />

            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 32, md: 43 },
                  color: colors.primary,
                  textTransform: "uppercase",
                }}
                lineHeight={1}
              >
                TRABAJA CON NOSOTROS
              </Typography>

              <Typography
                sx={{
                  fontSize: { xs: 16, md: 18 },
                  color: colors.gray,
                  marginTop: "10px",
                  lineHeight: 1.3,
                }}
              >
                ¿Quieres se parte de nuestra red de domiciliarios? Completa el
                formulario y nuestros asesores se pondrán en contacto contigo
                para afiliarte en nuestra plataforma
              </Typography>
            </Box>
          </Box>
          {/* Formulario */}
          <Box mt={5}>
            <Box
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
              sx={{ flexGrow: 1 }}
            >
              <Grid
                container
                spacing={2}
                width={"100%"}
                sx={{
                  height: "auto",
                  padding: "20px 0px",
                  marginBottom: { xs: 10, md: 20 },
                }}
              >
                <Grid
                  size={{ xs: 12, md: 6 }}
                  sx={{
                    background: colors.white,
                    height: "auto",
                    borderRadius: "18px",
                    boxShadow: "0px 7px 41.5px -11px #00000040",
                    padding: {
                      xs: "40px 20px 100px 20px",
                      sm: "40px 70px 100px 70px",
                    },
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      width: 136,
                      height: 10,
                      background: colors.primary,
                      position: "absolute",
                      left: "50%",
                      top: 0,
                      transform: "translatex(-50%)",
                    }}
                  />

                  {send ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-around",
                        height: "100%",
                      }}
                      className="zoom-in"
                    >
                      <Box>
                        <Box
                          sx={{
                            width: { xs: 180, lg: 235 },
                            height: { xs: 180, lg: 235 },
                            margin: "0 auto",
                          }}
                        >
                          <img
                            src={require("../../assets/image/check.webp")}
                            alt={""}
                            loading="lazy"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </Box>
                        <Typography style={{ textAlign: "center" }}>
                          <Typography
                            sx={{
                              color: "#1DA76C",
                              lineHeight: 0.9,
                              fontWeight: "bold",
                              letterSpacing: -1,
                              fontSize: {
                                xs: "1.5rem",
                                lg: "1.6rem",
                                xl: "2rem",
                              },
                            }}
                          >
                            ¡GRACIAS POR TU MENSAJE!
                          </Typography>
                          <Typography
                            style={{
                              textAlign: "center",
                              color: "#616161",
                              fontSize: 19,
                            }}
                            mt={0.5}
                          >
                            ¡Tu solicitud ha sido enviada!
                          </Typography>
                        </Typography>
                      </Box>

                      <Typography
                        style={{ textAlign: "center" }}
                        mt={10}
                        mb={12}
                      >
                        <Typography
                          style={{
                            textAlign: "center",
                            color: "#616161",
                            fontSize: 19,
                            lineHeight: 1.3,
                          }}
                          mx={8}
                          mt={0.5}
                        >
                          Pronto nuestros asesores se pondrán en contacto
                          contigo
                        </Typography>
                      </Typography>
                      <ColorButton
                        variant="contained"
                        sx={{ fontSize: 20 }}
                        onClick={() => {
                          navigate("/");
                          setSend(false);
                        }}
                      >
                        Finalizar
                      </ColorButton>
                    </Box>
                  ) : (
                    <>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: 19, md: 21.8 },
                            color: colors.black,
                            textAlign: "center",
                            marginBottom: "37px",
                          }}
                        >
                          Completa el formulario
                        </Typography>
                        <CssTextField
                          label="Nombre completo"
                          id="custom-css-outlined-input"
                          sx={{ width: { xs: "100%", sm: "80%", md: "auto" } }}
                          value={data.nombre}
                          onChange={(e) =>
                            setData({ ...data, nombre: e.target.value })
                          }
                          error={dataFormValidated.nombre}
                          helperText={
                            dataFormValidated.nombre
                              ? "El campo nombre es obligatorio"
                              : ""
                          }
                          disabled={isLoading}
                        />
                        <CssTextField
                          label="Ciudad"
                          id="custom-css-outlined-input"
                          sx={{ width: { xs: "100%", sm: "80%", md: "auto" } }}
                          value={data.ciudad}
                          onChange={(e) =>
                            setData({ ...data, ciudad: e.target.value })
                          }
                          error={dataFormValidated.ciudad}
                          helperText={
                            dataFormValidated.ciudad
                              ? "El campo ciudad es obligatorio"
                              : ""
                          }
                          disabled={isLoading}
                        />
                        <CssTextField
                          label="Número de contacto"
                          id="custom-css-outlined-input"
                          sx={{ width: { xs: "100%", sm: "80%", md: "auto" } }}
                          value={data.numero_contacto}
                          onChange={(e) =>
                            setData({
                              ...data,
                              numero_contacto: e.target.value,
                            })
                          }
                          onKeyPress={(e) => {
                            // Evitar que se ingresen caracteres que no son números
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          type="number"
                          error={dataFormValidated.numero_contacto}
                          helperText={
                            dataFormValidated.numero_contacto
                              ? "El campo número de contacto es obligatorio"
                              : ""
                          }
                          disabled={isLoading}
                        />
                        <CssTextField
                          label="Correo electrónico"
                          id="custom-css-outlined-input"
                          type="email"
                          sx={{ width: { xs: "100%", sm: "80%", md: "auto" } }}
                          value={data.correo}
                          onChange={(e) => {
                            setData({ ...data, correo: e.target.value });
                            setDataFormValidated({
                              ...dataFormValidated,
                              correo: false,
                            });
                          }}
                          error={dataFormValidated.correo}
                          helperText={
                            dataFormValidated.correo
                              ? "Ingresa un correo válido"
                              : ""
                          }
                          disabled={isLoading}
                        />
                      </Box>
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        mt={2.5}
                        mb={4}
                      >
                        <Checkbox
                          onChange={(e) =>
                            setData({ ...data, policy: e.target.checked })
                          }
                          disabled={isLoading}
                        />
                        <Typography
                          sx={{
                            fontSize: 13,
                            color: colors.black,
                          }}
                        >
                          He leído y acepto la{" "}
                          <span style={{ color: colors.primary }}>
                            Política de Tratamiento de Datos
                          </span>
                        </Typography>
                      </Box>
                      <ColorButton
                        variant="contained"
                        sx={{ fontSize: 20 }}
                        onClick={handleSend}
                        disabled={isFormIncomplete()}
                      >
                        {isLoading ? (
                          <CircularProgress
                            style={{ width: 25, height: 25, padding: 5 }}
                            color="inherit"
                          />
                        ) : (
                          "ENVIAR"
                        )}
                      </ColorButton>
                    </>
                  )}
                </Grid>
                <Grid
                  size={6}
                  sx={{
                    position: "relative",
                    display: { xs: "none", md: "flex" },
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: { md: "550px", lg: "650px" },
                      height: { md: "550px", lg: "650px" },
                    }}
                  >
                    <img
                      src={require("../../assets/image/trabajaNosotros.webp")}
                      alt={""}
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
