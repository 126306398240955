import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";
import { colors, sizes } from "../../styles/theme.tsx";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const CssTextField = styled(TextField)({
  "& label": {
    paddingLeft: "15px", // Aplica el padding-left cuando el label no está enfocado
    transition: "padding-left 0.4s ease-in-out", // Añade una transición suave
  },
  "& label.Mui-focused": {
    color: colors.primary,
    paddingLeft: "0px",
  },
  "& label.MuiInputLabel-shrink": {
    paddingLeft: "0px", // Elimina el padding cuando el label está shrinked (arriba)
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
    paddingLeft: "0px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
      borderRadius: 10,
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
      borderRadius: 10,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.primary,
      borderRadius: 10,
    },
    "& .MuiInputBase-inputMultiline": {
      resize: "vertical",
    },
  },
  background: colors.grayLight,
  width: "100%",
  borderRadius: 10,
  paddingLeft: "0px",
});

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: colors.white,
  backgroundColor: colors.primary,
  "&:hover": {
    backgroundColor: colors.white,
    border: `1px solid ${colors.primary}`,
    color: colors.primary,
  },
  borderRadius: 19,
  width: 245,
  fontWeight: 700,
  margin: "0 auto",
}));

export default function ContactUs() {
  const [send, setSend] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    nombre: "",
    correo: "",
    ciudad: "",
    numero_contacto: "",
    mensaje: "",
    policy: false,
  });
  const [dataFormValidated, setDataFormValidated] = useState({
    nombre: false,
    correo: false,
    ciudad: false,
    numero_contacto: false,
    mensaje: false,
    policy: false,
  });

  const validateForm = () => {
    const isNombreValid = data.nombre.trim() !== "";
    const isCorreoValid = /^\S+@\S+\.\S+$/.test(data.correo);
    const isCiudadValid = data.ciudad.trim() !== "";
    const isTelefonoValid =
      data.numero_contacto.trim() !== "" &&
      !isNaN(parseInt(data.numero_contacto));
    const isMensajeValid = data.mensaje.trim() !== "";

    setDataFormValidated({
      nombre: !isNombreValid,
      correo: !isCorreoValid,
      ciudad: !isCiudadValid,
      numero_contacto: !isTelefonoValid,
      mensaje: !isMensajeValid,
      policy: data.policy,
    });

    return (
      isNombreValid &&
      isCorreoValid &&
      isCiudadValid &&
      isTelefonoValid &&
      isMensajeValid &&
      data.policy
    );
  };

  // Función para verificar si los campos están vacíos y policy es false
  const isFormIncomplete = () => {
    return (
      !data.nombre ||
      !data.correo ||
      !data.ciudad ||
      !data.numero_contacto ||
      !data.mensaje ||
      !data.policy ||
      isLoading
    );
  };

  const handleSend = () => {
    if (validateForm()) {
      setIsLoading(true);
      const randomTime = Math.floor(Math.random() * (5000 - 2000 + 1)) + 2000;
      setTimeout(() => {
        setIsLoading(false);
        setSend(true);
      }, randomTime);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          flex: "1",
          flexGrow: 1,
          marginTop: `${sizes.header}px`,
          paddingTop: "60px",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            flexGrow: 1,
            width: { xs: "95%", lg: "60%", xl: "50%" },
            margin: "0 auto",
          }}
        >
          {/* Titulo */}
          <Box
            sx={{
              padding: "50px",
              position: "relative",
              display: "flex",
              width: { md: "90%" },
              margin: "0 auto",
            }}
          >
            <div
              style={{
                width: 10,
                height: 120,
                background: colors.primary,
                position: "absolute",
                top: "50%",
                left: 0,
                borderRadius: 2,
                transform: "translateY(-50%)",
              }}
            />

            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 32, md: 43 },
                  color: colors.primary,
                  textTransform: "uppercase",
                }}
                lineHeight={1}
              >
                COMUNÍCATE CON NOSOTROS22
              </Typography>

              <Typography
                sx={{
                  fontSize: { xs: 16, md: 18 },
                  color: colors.gray,
                  marginTop: "10px",
                  lineHeight: 1.3,
                }}
              >
                Completa el formulario y nuestros asesores se pondrán en
                contacto contigo. Ellos te asesorarán sobre las mejores
                soluciones para ti o para tu empresa.
              </Typography>
            </Box>
          </Box>
          {/* Formulario */}
          <Box mt={5} width={"100%"}>
            <Box
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
              flexDirection={"column"}
              sx={{ flexGrow: 1 }}
            >
              <Box
                sx={{
                  background: colors.white,
                  height: "auto",
                  borderRadius: "18px",
                  boxShadow: "0px 7px 41.5px -11px #00000040",
                  padding: {
                    xs: "40px 20px 100px 20px",
                    sm: "40px 70px 100px 70px",
                  },
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: { xs: 10, md: 20 },
                }}
              >
                <div
                  style={{
                    width: 136,
                    height: 10,
                    background: colors.primary,
                    position: "absolute",
                    left: "50%",
                    top: 0,
                    transform: "translatex(-50%)",
                  }}
                />

                {send ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-around",
                      height: "100%",
                    }}
                    className="zoom-in"
                  >
                    <Box>
                      <Box
                        sx={{
                          width: { xs: 180, lg: 235 },
                          height: { xs: 180, lg: 235 },
                          margin: "0 auto",
                        }}
                      >
                        <img
                          src={require("../../assets/image/check.webp")}
                          alt={""}
                          loading="lazy"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </Box>
                      <Typography style={{ textAlign: "center" }}>
                        <Typography
                          sx={{
                            color: "#1DA76C",
                            lineHeight: 0.9,
                            fontWeight: "bold",
                            letterSpacing: -1,
                            fontSize: {
                              xs: "1.5rem",
                              lg: "1.6rem",
                              xl: "2rem",
                            },
                          }}
                        >
                          ¡GRACIAS POR TU MENSAJE!
                        </Typography>
                        <Typography
                          style={{
                            textAlign: "center",
                            color: "#616161",
                            fontSize: 19,
                          }}
                          mt={0.5}
                        >
                          ¡Tu solicitud ha sido enviada!
                        </Typography>
                      </Typography>
                    </Box>

                    <Typography style={{ textAlign: "center" }} mt={10} mb={12}>
                      <Typography
                        style={{
                          textAlign: "center",
                          color: "#616161",
                          fontSize: 19,
                          lineHeight: 1.3,
                        }}
                        mx={8}
                        mt={0.5}
                      >
                        Pronto nuestros asesores se pondrán en contacto contigo
                      </Typography>
                    </Typography>
                    <ColorButton
                      variant="contained"
                      sx={{ fontSize: 20 }}
                      onClick={() => {
                        navigate("/");
                        setSend(false);
                      }}
                    >
                      Finalizar
                    </ColorButton>
                  </Box>
                ) : (
                  <>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 19, md: 21.8 },
                          color: colors.black,
                          textAlign: "center",
                          marginBottom: "37px",
                        }}
                      >
                        Escríbenos tu mensaje
                      </Typography>
                      <Grid container spacing={2.5} width={"100%"}>
                        <Grid size={{ xs: 12, md: 6 }}>
                          <CssTextField
                            label="Nombre completo"
                            id="custom-css-outlined-input"
                            value={data.nombre}
                            onChange={(e) =>
                              setData({ ...data, nombre: e.target.value })
                            }
                            error={dataFormValidated.nombre}
                            helperText={
                              dataFormValidated.nombre
                                ? "El campo nombre es obligatorio"
                                : ""
                            }
                            disabled={isLoading}
                          />
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                          <CssTextField
                            label="Correo electrónico"
                            id="custom-css-outlined-input"
                            type="email"
                            value={data.correo}
                            onChange={(e) => {
                              setData({ ...data, correo: e.target.value });
                              setDataFormValidated({
                                ...dataFormValidated,
                                correo: false,
                              });
                            }}
                            error={dataFormValidated.correo}
                            helperText={
                              dataFormValidated.correo
                                ? "Ingresa un correo válido"
                                : ""
                            }
                            disabled={isLoading}
                          />
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                          <CssTextField
                            label="Ciudad"
                            id="custom-css-outlined-input"
                            value={data.ciudad}
                            onChange={(e) =>
                              setData({ ...data, ciudad: e.target.value })
                            }
                            error={dataFormValidated.ciudad}
                            helperText={
                              dataFormValidated.ciudad
                                ? "El campo ciudad es obligatorio"
                                : ""
                            }
                            disabled={isLoading}
                          />
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                          <CssTextField
                            label="Nit de empresa (si aplica)"
                            id="custom-css-outlined-input"
                            disabled={isLoading}
                          />
                        </Grid>
                        <Grid size={12}>
                          <CssTextField
                            label="Número de contacto"
                            id="custom-css-outlined-input"
                            value={data.numero_contacto}
                            onChange={(e) =>
                              setData({
                                ...data,
                                numero_contacto: e.target.value,
                              })
                            }
                            onKeyPress={(e) => {
                              // Evitar que se ingresen caracteres que no son números
                              if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            type="number"
                            error={dataFormValidated.numero_contacto}
                            helperText={
                              dataFormValidated.numero_contacto
                                ? "El campo número de contacto es obligatorio"
                                : ""
                            }
                            disabled={isLoading}
                          />
                        </Grid>
                        <Grid size={12}>
                          <CssTextField
                            label="Escribe tu mensaje"
                            id="custom-css-outlined-input"
                            multiline
                            maxRows={4}
                            minRows={4}
                            value={data.mensaje}
                            onChange={(e) =>
                              setData({ ...data, mensaje: e.target.value })
                            }
                            error={dataFormValidated.mensaje}
                            helperText={
                              dataFormValidated.mensaje
                                ? "El campo descripcion es obligatorio"
                                : ""
                            }
                            disabled={isLoading}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      mt={2.5}
                      mb={4}
                      sx={{ flexDirection: "column" }}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Checkbox
                          onChange={(e) =>
                            setData({ ...data, policy: e.target.checked })
                          }
                          disabled={isLoading}
                        />
                        <Typography
                          sx={{
                            fontSize: 13,
                            color: colors.black,
                          }}
                        >
                          He leído y acepto la{" "}
                          <span style={{ color: colors.primary }}>
                            Política de Tratamiento de Datos
                          </span>
                        </Typography>
                      </Box>
                    </Box>

                    <ColorButton
                      variant="contained"
                      sx={{ fontSize: 20 }}
                      onClick={handleSend}
                      disabled={isFormIncomplete()}
                    >
                      {isLoading ? (
                        <CircularProgress
                          style={{ width: 25, height: 25, padding: 5 }}
                          color="inherit"
                        />
                      ) : (
                        "ENVIAR"
                      )}
                    </ColorButton>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
